.uploadData {
  width: 80%
}


.refresh {
  background-color: transparent;
  .refresh-section {
    background-color: transparent;
    .refresh-button {
      padding-top: 30px;
      background-color: transparent;
    }
  }
}

.upload-button-div {
  padding-top: 56px;
  padding-bottom: 56px;
  .file-browser {
    display: none;
  }
  .upload-button {
    color: rgb(64,0,149);
    border-color: rgb(64,0,149);
  }
}

.uploadData-header {
  color: rgb(64,0,149);
  padding-top: 20px;
  padding-bottom: 20px;
}

.menu-selected {
  color: rgb(64, 0, 149);
}

.upload-cp-div {
  color:  rgb(64,0,149);
  text-align: center;
  align-items: center;
  .circular-progress {
    color:  rgb(64,0,149);
  }
}

.dialog-div-verify {
  padding-left: 62px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 366px;
  text-align: center;
  align-items: center;
  .circular-progress {
    color:  rgb(64,0,149);
  }
}

.dialog {
  max-height: 500px;
  .dialog-content {
    width: 500px;
  }
  .upload-message {
    padding-left: 60px;
    padding-right: 30px;
  }
  .upload-message-verify {
    padding-left: 60px;
    padding-right: 30px;
    padding-bottom: 16px;
  }
  .dialog-title {
    text-align: center;
    align-items: center;
  }
  .dialog-button {
    color: rgb(64,0,149);
    background: transparent;
  }
  .success-icon-div {
    text-align: center;
    align-items: center;
    color: #439400;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .success-icon {
    transform: scale(3);
  }
  .cancel-icon-div {
    text-align: center;
    align-items: center;
    color: red;
    padding-bottom: 16px;
  }
  .cancel-icon {
    transform: scale(2);
    padding-top: 4px;
    padding-left: 4px;
  }
  .error-icon-div {
    text-align: center;
    align-items: center;
    color: red;
    padding-bottom: 16px;
  }
  .error-icon {
    transform: scale(2);
  }
  .dialog-table {
    .dialog-row {     
      color: #400095;
      .dialog-row-10 {
        width: 10%;
        border-bottom: none;
        color: #400095;
        padding-top: 8px;
        padding-bottom: 8px;
        .file-icon {
          transform: scale(1.2);
        }
        .error-icon {
          padding-top: 6px;
          transform: scale(1.2);
          color: red;
          padding-left: 22px;
          padding-top: 8px;
        }
        .warning-icon {
          transform: scale(1.2);
          color: #FFCC00;
          padding-left: 22px;
          padding-top: 8px;
        }
        .cancel-button {
          background: transparent;
          width: 46px;
          min-width: 46px;
          padding-left: 26px;     
          .cancel-icon {
            transform: scale(1.2);
            color: red;
          }
        }
        .cloud-icon-div {
          position: relative;
          display: flex;
          padding-left: 12px;
          padding-bottom: 6px;
        }
        .cloud-icon {
          color: #5283b2;
          padding-left: 10px;
          padding-top: 8px;
          transform: scale(1.2);
        }
        .cloud-done-icon {
          padding-left: 22px;
          padding-top: 8px;
          color: #5283b2;
          transform: scale(1.2);
        }
        .cloud-circular-progress {
          position: absolute;
          color: #5283b2;
        }
      }
      .dialog-row-80{
        padding-top: 8px;
        width: 80%;
        max-width: 334px;
        border-bottom: none;
      }
      .dialog-div {
        text-align: center;
        align-items: center;
        .barcolor {
          background-color: #400095;
          height: 5px;
        }
        .color {
          background-color: #dcccf0;
          height: 5px;
        }
      }
      .dialog-file-div {
        padding-bottom: 6px;
        color: black;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.history {
  .recent-header {
    color: rgb(64,0,149);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .upload-cp-div {
    color:  rgb(64,0,149);
    align-items: center;
    text-align: center;
    .circular-progress {
      color:  rgb(64,0,149);
    }
  }
}

.select-boxes {
  .select {
  }
  .select::before {
    border-color: black;
  }
  .select::after {
    border-color: rgb(64, 0, 149);
  }
}





