.user-group {
  .select-boxes-admin {
    display: flex;
    .form-control {
      min-width: 140px;
    }
    .modify-bu-button {
      background-color: transparent;
      padding-top: 20px;
    }
    .menu-selected {
      width: 280px;
    }
    .menu-selected::before {
      border-color: black;
    }
    .menu-selected::after {
      border-color: rgb(64, 0, 149);
    }
  }
  .admin-header {
    color: rgb(64,0,149);
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.user-dialog {
  .user-dialog-content {
    width: 400px;
    .sso-div {
      padding-bottom: 20px;
      .sso-text-box {
      }
      .sso-text-box::before {
        border-color: black;
      }
      .sso-text-box::after {
        border-color: rgb(64, 0, 149)
      }
      .sso-text-box-error {
        color: red;
      }
    }
    .admin-div {
    }
  }
  .user-dialog-title {
    text-align: center;
    align-items: center;
  }
  .user-dialog-modify-button {
    justify-content: space-between;
    .left-button-div {
      float: left; 
      .user-delete-button {
        color: red;
        background: transparent;
      }
    }
    .right-button-div {
      float: right;    
      .user-save-cancel-button {
        color: rgb(64,0,149);
        background: transparent;
      }
    }
  }
  .user-dialog-button {
    color: rgb(64,0,149);
    background: transparent;
  }
  .user-dialog-delete-button {
    color: red;
    background: transparent;
  }
  .error-icon-div {
    text-align: center;
    align-items: center;
    color: red;
    padding-bottom: 16px;
  }
  .error-icon {
    transform: scale(2);
  }
  .text-box::before {
    border-color: black;
  }
  .text-box::after {
      border-color: rgb(64, 0, 149)
  }
  .bu-name {
    width: 250px;
    padding-bottom: 10px;
    .name-text-box-error {
        color: red;
    }
}
  .bu-desc {
    padding-bottom: 10px;
    width: 400px;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
}
}
.select-box::before {
  border-color: black;
}
.select-box::after {
  border-color: rgb(64, 0, 149)
}