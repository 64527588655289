main {
  padding-top: 64px;
  padding-bottom: 20px;
  flex: 1 0 auto;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-container {
  margin: auto;
  width:100%;
  max-width: 1200px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.app-container-center {
  margin: auto;
  width:100%;
  max-width: 1200px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root, .app {
  height: 100%;
}

.authenticate-message {
  align-items: center;
  display: flex;
  justify-content: center;
}

.cp-div {
  text-align: center;
  align-items: center;
  padding-top: 40px;
  color: rgb(64, 0, 149);
  .circular-progress {
    color: rgb(64, 0, 149);
  }
}