.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer {
  background: black;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}

.footer-text {
  color: white;
  font-family: 'Mark Pro';
}

.footer-text-contact {
  font-size: 14px;
  min-height: 30px;
}

.link {
  text-decoration:none;
  color: rgb(178,178,178);
}

.link:hover {
  color: white;
}