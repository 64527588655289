.dataset-bu-table {
  width: 60%;
  .header-row {
    background: rgb(64,0,149);
    .header-row-40 {
      width: 40%;
      color: white;
    }
    .header-row-60 {
      width: 60%;
      color: white;
    }
  }
}
