.filter-title {
  padding-top: 40px;
  padding-bottom: 20px;
}

.filter-button-item {
  padding-top: 56px;
  .export-button {
    color: rgb(64, 0, 149);
    border-color: rgb(64, 0, 149);
  }
}

.columns-selectall {
  padding-top: 40px;
}

.columns {
  padding-top: 56px;
  text-overflow: ellipsis;
  .columns-label {
    padding-bottom: 16px;
    .columns-label:focus {
      color: #757575;
    }
  }
  .columns-top {
    padding-top: 32px;
  }
}

.filters {
  padding-top: 16px;
}

.operator {
  padding-left: 32px;
  .select-box {
  }
  .select-box::before {
    border-color: black;
  }
  .select-box::after {
    border-color: rgb(64, 0, 149)
  }
}
.criteria {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  .delete-button {
    padding-top: 22px;
    padding-right: 40px;
    padding-left: 0;
  }
  .delete-button:hover {
    background-color: transparent;
  }
  .select-box {
    width: 192px;
    max-width: 192px;
  }
  .select-box::before {
    border-color: black;
  }
  .select-box::after {
    border-color: rgb(64, 0, 149)
  }
  .text-box {
    width: 250px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 16px;
  }
  .text-box::before {
    border-color: black;
  }
  .text-box::after {
    border-color: rgb(64, 0, 149)
  }
}

.form-control-label {
  max-width: 290px;
  text-overflow: ellipsis;
  width: 290px;
  overflow: hidden;
  .checkbox {
    color: #400095;
  }
  .checkbox:hover {
    background-color: transparent;
  }
}



