.bu-groups-header {
    color: rgb(64,0,149);
    padding-top: 80px;
    padding-bottom: 20px;
}
.admin-header {
    color: rgb(64,0,149);
    padding-top: 20px;
    padding-bottom: 20px;
}
.platform {
    .select-boxes {
        .form-control {
            min-width: 140px;
        }
    }
}
.platform-dialog {
    .platform-dialog-title {
        text-align: center;
        align-items: center;
    }
    .platform-dialog-content {
        .bu-group-text {
            width: 250px;
        }
        .bu-group-text::before {
            border-color: black;
        }
        .bu-group-text::after {
            border-color: rgb(64, 0, 149);
        }
        .bu-group-text-error {
            width: 250px;
            color: red;
        }
        .bu-group-text-error::before {
            border-color: red;
        }
        .bu-group-text-error::after {
            border-color: red;
        }
    }
    .bu-name {
        width: 250px;
        padding-bottom: 10px;
        .name-text-box-error {
            color: red;
        }
    }
    .platform-dialog-button {
        color: rgb(64, 0, 149);
    }
    .platform-dialog-delete-button {
        color: red;
    }
    .bu-admin {
        padding-bottom: 10px;
        .admin-text-box-error {
            color: red;
        }
    }
    .bu-desc {
        padding-bottom: 10px;
        width: 400px;
        max-width: 400px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .text-box::before {
        border-color: black;
    }
    .text-box::after {
        border-color: rgb(64, 0, 149)
    }
    .error-icon-div {
        text-align: center;
        align-items: center;
        color: red;
        padding-bottom: 16px;
    }
    .error-icon {
        transform: scale(2);
    }
}