.browse-table {
  width: 80%;
  .table-body {
    .dataset-row {
      background: rgb(247,247,247);
      .dataset-row-6 {
        width: 6%;
        max-width: 6%;
      }
      .dataset-row-8 {
        width: 8%;
        max-width: 8%;
      }
      .dataset-row-11 {
        width: 11%;
        max-width: 11%;
      }
      .dataset-row-14 {
        width: 14%;
        max-width: 14%;
      }
      .dataset-row-21 {
        width: 21%;
        max-width: 21%;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;
      }
      .dataset-row-21-name {
        width: 21%;
        max-width: 21%;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  .header-row {
    background: rgb(64,0,149);
    .header-row-5 {
      width: 5%;
      max-width: 5%;
      color: white;
    }
    .header-row-6 {
      width: 6%;
      max-width: 6%;
      color: white;
    }
    .header-row-7 {
      width: 7%;
      max-width: 7%;
      color: white;
    }
    .header-row-8 {
      width: 8%;
      max-width: 8%;
      color: white;
    }
    .header-row-9 {
      width: 9%;
      max-width: 9%;
      color: white;
    }
    .header-row-10 {
      width: 10%;
      max-width: 10%;
      color: white;
    }
    .header-row-11 {
      width: 11%;
      max-width: 11%;
      color: white;
    }
    .header-row-13 {
      width: 13%;
      max-width: 13%;
      color: white;
    }
    .header-row-14 {
      width: 14%;
      max-width: 14%;
      color: white;
    }
    .header-row-20 {
      width: 20%;
      max-width: 20%;
      color: white;
    }
    .header-row-21 {
      width: 21%;
      max-width: 21%;
      color: white;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
}

.expandy-table {
  .export-button {
    color: rgb(64,0,149);
    border-color: rgb(64,0,149);
  }
}

.expandy-button {
  background-color: transparent;
}

.expandy-row {
  word-wrap: break-word;
  .expandy-row-cell {
    padding: 0;
    border: none;
  }
}

.expandy-table-row {
  .expandy-cell-5 {
    width: 5%;
    max-width: 5%;
  }
  .expandy-cell-6 {
    width: 6%;
    max-width: 6%;
  }
  .expandy-cell-7 {
    width: 7%;
    max-width: 7%;
  }
  .expandy-cell-8 {
    width: 8%;
    max-width: 8%;
  }
  .expandy-cell-9 {
    width: 9%;
    max-width: 9%;
  }
  .expandy-cell-10 {
    width: 10%;
    max-width: 10%;
   }
  .expandy-cell-11 {
    width: 11%;
    max-width: 11%;
  }
  .expandy-cell-13 {
    width: 13%;
    max-width: 13%;
  }
  .expandy-cell-14 {
    width: 14%;
    max-width: 14%;
  }
  .expandy-cell-21 {
    width: 21%;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 21%;
    word-break: break-word;  
  }
  .expandy-cell-name-21 {
    width: 21%;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 21%;
    word-break: break-all;
  }
}

.dataset-row-s3 {
  padding-left: 14px;
  width: 30px;
  color: rgb(67,148,0);
}

.browse-cp-div {
  text-align: center;
  align-items: center;
  color: rgb(64, 0, 149);
  .circular-progress {
    color: rgb(64, 0, 149);
  }
}


