.user-table {
  width: 60%;
  .header-row {
    background: rgb(64,0,149);
    .header-row-20 {
      width: 20%;
      color: white;
    }
    .header-row-40 {
      width: 40%;
      color: white;
    }
  }
  .user-row {
    .success-icon-div {
      text-align: center;
      align-items: center;
      color: #439400;
    }
    .success-icon {
      transform: scale(1.3);
    }
    .modify-button {
    }
    .modify-button:hover {
      background-color: transparent;
    }
  }
}
