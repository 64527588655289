.history-table {
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  width: 100%;
  .history-row {
    background: rgb(247,247,247);
    .history-cell-status {
      justify-content: center;
      align-items: center;
      padding-top: 18.5px;
      padding-bottom: 18.5px;
      width: 15%;
      max-width: 15%;
      .error-status {
        background-color: transparent;
      }
      .error-status:hover {
        background-color: transparent;
      }
      .error-icon {
        transform: scale(1.2);
        color: red;
      }
      .success-icon {
        transform: scale(1.2);
        color: #439400;
      }
      .cloud-icon-div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cloud-done-icon {
        color: #5283b2;
        transform: scale(1.2);
      }
      .cloud-circular-progress {
        position: absolute;
        color: #5283b2;
      }
    }
    .history-cell-15 {
      width: 15%;
      max-width: 15%;
    }
    .history-cell-20 {
      width: 20%;
      max-width: 20%;
    }
    .history-cell-30 {
      width: 30%;
      max-width: 30%;
    }
  }
  .header-row {
    background: rgb(64,0,149);   
    .header-row-15 {
      width: 15%;
      max-width: 15%;
      color: white
    }
    .header-row-20 {
      width: 20%;
      max-width: 20%;
      color: white;
    }
    .header-row-30 {
      width: 30%;
      max-width: 30%;
      color: white;
    }
    .header-row-status {
      width: 15%;
      max-width: 15%;
      color: white;
      align-items: center;
      align-content: center;
    }
  }
  .footer-row {
    flex-shrink: 0;
    margin-left: spacing(4);
  }
}

.upload-text {
    color: rgb(134, 121, 121);
}



