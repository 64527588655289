.exportData {
  width: 80%
}

.exportData-header {
  color: rgb(64,0,149);
  padding-top: 20px;
  padding-bottom: 20px;
}

.select-boxes {
  .select {
  }
  .select::before {
    border-color: black;
  }
  .select::after {
    border-color: rgb(64, 0, 149);
  }
  .menu-selected {
    width: 280px;
  }
  .menu-selected::before {
    border-color: black;
  }
  .menu-selected::after {
    border-color: rgb(64, 0, 149);
  }
}

.button-item {
  padding-top: 40px;
  .export-button {
    color: rgb(64, 0, 149);
    border-color: rgb(64, 0, 149);
  }
  .export-button-loading {
    color: rgb(64, 0, 149);
    border-color: rgb(64, 0, 149);
    width: 114.11px;
    height: 36px;
    .circular-progress {
      color: rgb(64, 0, 149);
    }
  }
}

.input-label {
  color: rgb(64, 0, 149);
}

.export-cp-div {
  color:  rgb(64,0,149);
  text-align: center;
  align-items: center;
  .circular-progress {
    color:  rgb(64,0,149);
  }
}

.dialog-div-query {
  align-items: center;
  .barcolor {
    background-color: #400095;
  }
  .color {
    background-color: #dcccf0;
  }
}


.query-dialog {
  .dialog-content {
    align-items: center;
  }
  .dialog-button {
    color: rgb(64,0,149);
    background: transparent;
  }
  .dialog-title {
    text-align: center;
    align-items: center;
  }
  .success-icon-div {
    text-align: center;
    align-items: center;
    color: #439400;
    padding-bottom: 16px;
  }
  .success-icon {
    transform: scale(2);
  }
  .error-icon-div {
    text-align: center;
    align-items: center;
    color: red;
    padding-bottom: 16px;
  }
  .error-icon {
    transform: scale(2);
  }
}
